import { TrialHistoryStatus } from '../../enums';

const trialStatusMapping = {
  [TrialHistoryStatus.PENDING]: 'Status: Pendente',
  [TrialHistoryStatus.IN_PROGRESS]: 'Status: Em progresso',
  [TrialHistoryStatus.TO_CHECK]: 'Status: A conferir',
  [TrialHistoryStatus.IN_CONFERENCE]: 'Status: Em conferência',
  [TrialHistoryStatus.DOCUMENTATION_REQUEST]: 'Status: Doc. pendente',
  [TrialHistoryStatus.DOCUMENTATION_PROVIDED]: 'Status: Doc. fornecida',
  [TrialHistoryStatus.CHECKED]: 'Status: Conferido',
  [TrialHistoryStatus.WITHDRAWAL]: 'Status: Destituído',
  [TrialHistoryStatus.SENT]: 'Status: Enviado',
  [TrialHistoryStatus.UPDATE]: 'Prazo ou observações modificadas',
};

const getTrialHistoryStatusLabel = (trialHistoryStatus: TrialHistoryStatus) => trialStatusMapping[trialHistoryStatus];

export default getTrialHistoryStatusLabel;
